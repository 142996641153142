import React from "react";

// Components
import {
	IconMail,
	IconPhone,
	IconLocation,
	IconDocument,
	Logo,
} from "../icons";

const Footer = ({ text }) => (
	<footer className="footer flex flex__wrap wrap flex__space">
		<div className="grid__3">
			<div>
				<Logo className="logo c__main mt__s mb__m" />
			</div>
			<a
				className="flex flex__align link"
				target="_blank"
				rel="noopener noreferrer"
				href={`${process.env.PUBLIC_URL}/assets/documents/AVG Beleidsrichtlijn.pdf`}
			>
				<IconDocument className="icon icon__s mr__s" />
				<span className="fs__s">AVG Beleidsrichtlijn</span>
			</a>
			<a
				className="flex flex__align pt__s link pt__s"
				target="_blank"
				rel="noopener noreferrer"
				href={`${process.env.PUBLIC_URL}/assets/documents/Datalek procedure.pdf`}
			>
				<IconDocument className="icon icon__s mr__s" />
				<span className="fs__s">Datalek procedure</span>
			</a>
			<a
				className="flex flex__align pt__s link pt__s"
				target="_blank"
				rel="noopener noreferrer"
				href={`${process.env.PUBLIC_URL}/assets/documents/Kwaliteitshandboek Smart AF v1.pdf`}
			>
				<IconDocument className="icon icon__s mr__s" />
				<span className="fs__s">Kwaliteitshandboek Smart AF</span>
			</a>
			<a
				className="flex flex__align pt__s link pt__s"
				target="_blank"
				rel="noopener noreferrer"
				href={`${process.env.PUBLIC_URL}/assets/documents/Vertrouwenspersoon & Klachtenprocedure.pdf`}
			>
				<IconDocument className="icon icon__s mr__s" />
				<span className="fs__s">
					Vertrouwenspersoon & Klachtenprocedure
				</span>
			</a>
		</div>
		<div className="grid__3 mb__m">
			<h3>{text.TITLE}</h3>
			<p className="fs__s pt__s">{text.DESCRIPTION}</p>
			<i className="pt__s fs__xs">Team Smart A.F.</i>
		</div>
		<div className="grid__3 mb__m">
			<h3>Contact</h3>
			<a
				href="mailto:info@smartaf.nl"
				className="flex flex__align pt__s link"
			>
				<IconMail className="icon icon__s mr__s" />
				<span className="fs__s">info@smartaf.nl</span>
			</a>
			<a className="flex flex__align pt__s link" href="tel:0627211212">
				<IconPhone className="icon icon__s mr__s" />
				<span className="fs__s ">06-46440481</span>
			</a>
			<a
				className="flex flex__align pt__s link"
				href="https://bit.ly/2kzwwgE"
				target="_blank"
				rel="noopener noreferrer"
			>
				<IconLocation className="icon icon__s mr__s" />
				<span className="fs__s">Marskant 21, 7551 BS Hengelo</span>
			</a>
		</div>
	</footer>
);

export default Footer;
